import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { changeLanguage, CONTACT_DETAILS } from '../../../config/constants';



const Mainnav = () => {

   const [isActive, setActive] = useState(false);
   const { t } = useTranslation();

   const click = (e) => {
      return e.preventDefault()
   }

   return (
      <>

         <header id="pt-header" className="pt-header-style-2 pt-has-sticky">
            <div className="row no-gutters main-div-container">
               <div className="col-lg-2 col-md-4">
                  <a className="navbar-brand" href="/#">
                     <img className="img-fluid logo" src="images/logo-white.png" alt="architeck" />
                  </a>
               </div>
               <div className="col-lg-10 col-md-8">
                  <div className="pt-top-header">
                     <div className="row no-gutters">
                        <div className="col-lg-3 col-xl-4">
                           <div className="pt-header-social ">
                              
                           </div>
                        </div>
                        <div className="col-lg-9 col-xl-8">
                           <div className="pt-header-contact text-right">
                              <ul>
                                 <li>
                                    <a href={"tel:" + CONTACT_DETAILS.PHONE.replace("-", "")}><i className="fas fa-phone"></i>
                                       <span>{CONTACT_DETAILS.PHONE}</span>
                                    </a>
                                 </li>
                                 <li>
                                    <a href={"mailto:" + CONTACT_DETAILS.EMAIL}><i className="fas fa-envelope"></i><span>{CONTACT_DETAILS.EMAIL}</span></a>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="pt-bottom-header">
                     <div className="row no-gutters">
                        <div className="col-lg-12">
                           <nav className="navbar navbar-expand-lg navbar-light">
                              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                 <i className="fas fa-bars"></i>
                              </button>
                              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                 <div id="pt-menu-contain" className="pt-menu-contain">
                                    <ul id="pt-main-menu" className="navbar-nav ml-auto">
                                       <li id="home">
                                          <a className="nav-item" href="/">
                                             {/* eslint-disable-next-line */}
                                             <a href="/">
                                                {t("Home")}
                                                {/* <i className="ion-chevron-down mr-2"></i> */}
                                             </a>
                                             {/* <ul className="sub-menu">
                                                <li><a href="/#AboutUs">{t("AboutUs")}</a></li>
                                                <li><a href="/#recent">{t("OurWork")}</a></li>
                                                <li><a href="/#iservices">{t("ServicesTitle")}</a></li>
                                                <li><a href="/#team">{t("OurExpertTeam")}</a></li>
                                                <li><a href="/#clients">{t("ourClients")}</a></li>
                                             </ul> */}
                                          </a>
                                       </li>
                                       <li id="about">
                                          <a className="nav-item" href="/aboutus">{t("AboutUs")}</a>
                                       </li>
                                       <li id="services">
                                          <a className="nav-item" href="/ourservices">{t("OurServices")}</a>
                                       </li>
                                       <li id="contact">
                                          <a className="nav-item" href="/Contactus">{t("ContactUs")}</a>
                                       </li>
                                       <li id="fiber">
                                          <a className="nav-item" href="/fiber">{t("Fiber")}</a>
                                       </li>
                                       <li onClick={() => setActive(!isActive)} className={`${isActive ? "active" : ""}`}>
                                          {/* eslint-disable-next-line */}
                                          <a href="#">
                                             <i style={{ fontSize: 23 }} className="fa fa-globe"></i>
                                             <i className="ion-chevron-down"></i>
                                          </a>
                                          <ul className="sub-menu">
                                             {/* eslint-disable-next-line */}
                                             <li onClick={() => changeLanguage("en")}><a href="#">English</a></li>
                                             {/* eslint-disable-next-line */}
                                             <li onClick={() => changeLanguage("he")}><a href="#">עברית</a></li>
                                          </ul>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="pt-header-info-box">
                                 <div className="pt-toggle-btn">
                                    <a href="#!" onClick={click} className="menu-toggle">Menu</a>
                                 </div>
                              </div>
                           </nav>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>

      </>
   );

}
export default Mainnav;