import React from 'react';
import { useTranslation } from 'react-i18next';
import { CONTACT_DETAILS } from '../config/constants';



const Footer = () => {
   
   const {t} = useTranslation();
   
   return (
      <>
         <footer id="pt-footer">
            <div className="pt-footer-style-1">
               <div className="pt-footer-top">
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-3 col-md-6">
                           <div className="footer-logo">
                              <div className="row">
                                 <div className="col-sm-12">
                                    <img className="img-fluid pt-footer-logo" src="images/logo-white.png" alt="logo-white" />
                                    <p className="text-white">{t("AbuMokhTashtioit")}</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                           <div className="pt-footer1">
                              <h4 className="footer-title text-white">{t("QuickLinks")}</h4>
                              <div className="menu-footer-menu-container">
                                 <ul id="menu-footer-menu" className="menu">
                                    <li>
                                       <a href="/">{t("Home")}</a>
                                    </li>
                                    <li>
                                       <a href="/aboutus">{t("AboutUs")}</a>
                                    </li>
                                    <li>
                                       <a href="/ourservices">{t("OurServices")}</a>
                                    </li>
                                    <li>
                                       <a href="/contactus">{t("ContactUs")}</a>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-3  col-md-6 ">
                           <div className="pt-footer1-menu pt-footer1">
                              <h4 className="footer-title">{t("Categories")}</h4>
                              <div className="list-inline pt-footer1-menu">
                                 <ul className="pt-service">
                                    {Array.from({length: 6}, (_, i) => i).map((_, ind) => 
                                       // eslint-disable-next-line
                                       <li key={ind}><a href="#">{t("Services.s" + (ind + 1))}</a></li>
                                    )}
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-3  col-md-6">
                           <div className="pt-footer2">
                              <h4 className="footer-title text-white">{t("ContactInfo")}</h4>
                              <div className="row">
                                 <div className="col-sm-12">
                                    <ul className="pt-contact pt-2">
                                       <li>
                                          <i className="fas fa-map-marker-alt"></i>
                                          <span className="text-white">{t("CompanyAddressValue")}</span>

                                       </li>
                                       <li className="pt-3">
                                          <a href={"tel:" + CONTACT_DETAILS.PHONE.replace("-", "")}><i className="fa fa-phone"></i>
                                             <span className="text-white">{CONTACT_DETAILS.PHONE}</span>
                                          </a>
                                       </li>
                                       <li className="pt-3">
                                          <a href={"mailto:" + CONTACT_DETAILS.EMAIL}><i className="fa fa-envelope"></i><span
                                             className="text-white">{CONTACT_DETAILS.EMAIL}</span></a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="pt-copyright-footer">
                  <div className="container">
                     <div className="row flex-row-reverse">
                        <div className="col-md-6 text-right align-self-center">
                           <span className="pt-copyright">
                              Copyright {new Date().getFullYear()} Abu Mokh Tashtiot
                              |
                              Designed & Developed by
                              <a target="_blank" href="http://jadinfinitesol.com" rel="noreferrer"> Jad InfiniteSol</a>
                           </span>
                        </div>
                        <div className="col-md-6">
                           <div className="pt-footer-social">
                              
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );

}
export default Footer;