import React from 'react';
import { useTranslation } from 'react-i18next';
import Reactcard from './Recentcard';

const projects = [
   { img: "images/project/01.jpg", name: "Project 1" },
   { img: "images/project/02.jpg", name: "Project 2" },
   { img: "images/project/04.jpg", name: "Project 3" },
   { img: "images/project/05.jpg", name: "Project 4" },
   { img: "images/project/06.jpg", name: "Project 5" },
   { img: "images/project/07.jpg", name: "Project 6" },
   { img: "images/project/08.jpg", name: "Project 7" },
   { img: "images/project/09.jpg", name: "Project 8" }
];

const Recent = () => {

   const { t } = useTranslation();

   return (
      <>
         <section className="recent-projects pt-bg-dark section-style wow animate__fadeIn" data-wow-delay="0.2s" id="recent">
            <div className="pt-bg-overley1" style={{ backgroundImage: "url('images/bg/01.png')" }}></div>
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 z-index-1">
                     <div className="pt-section-title-box text-xl-left text-center  mb-md-0">
                        <span className="pt-section-sub-title text-white border-white">{t("OurWork")}</span>
                        <h2 className="pt-section-title text-white">{t("ExploreRecentProjects")}</h2>
                     </div>
                  </div>
               </div>
            </div>
            <div className="container-pt pt-4">
               <div className="row">
                  <div className="col-sm-12">
                     <div className="owl-carousel" data-dots="false" data-nav="false" data-desk_num="4" data-lap_num="2"
                        data-tab_num="2" data-mob_num="1" data-mob_sm="1" data-autoplay="true" data-loop="true"
                        data-margin="30">
                        {projects.map((val, ind) =>
                           <Reactcard
                              key={ind}
                              img={val.img}
                              name={val.name}
                           />
                        )}
                     </div>
                  </div>
               </div>
            </div>
            <div className="pt-section-big-title bottom text-center wow animate__fadeInUp " data-wow-duration="1s"
               data-wow-delay="1s">
               <h2 className="big-heading-title">{t("OurWork")}</h2>
            </div>
         </section>
      </>
   );

}
export default Recent;