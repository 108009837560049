import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Testimonialcard from './Testimonialcard';


const Testimonial = () => {

   const { t } = useTranslation();

   const ourClientsSays = [
      { img: "images/client/m1.jpg" },
      { img: "images/client/m2.jpg" },
      { img: "images/client/m3.jpg" },
      { img: "images/client/m4.jpg" },
   ]
   
   useEffect(() => {

      setTimeout(() => {
         try {

            let prevButtons = document.getElementsByClassName("owl-prev");
            let nextButtons = document.getElementsByClassName("owl-next");

            for (let i = 0; i < prevButtons.length; i++) {
               let span = prevButtons[i].querySelector("span");
               span.textContent = t("prev");
            }

            for (let i = 0; i < nextButtons.length; i++) {
               let span = nextButtons[i].querySelector("span");
               span.textContent = t("next");
            }
         } catch (e) {
            console.error(e);
         }

      }, 1000);

      //eslint-disable-next-line
   }, []);

   return (
      <>
         <section className="testimonial pt-bg-dark" style={{ backgroundImage: "url('images/bg/03.jpg')" }} id="clients">
            <div className="container">
               <div className="row">
                  <div className="col-lg-6">
                     <div className="pt-section-title-box text-lg-left text-center">
                        <span className="pt-section-sub-title text-white border-white">{t("OurClientsSay")}</span>
                        <h2 className="pt-section-title text-white">{t("ClientsSayAboutUs")}</h2>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-sm-12">
                     <div className="pt-testimonial pt-testimonial-1">
                        <div className="owl-carousel owl-loaded owl-drag" data-dots="false" data-nav="true" data-desk_num="2"
                           data-lap_num="2" data-tab_num="1" data-mob_num="1" data-mob_sm="1" data-autoplay="true"
                           data-loop="true" data-margin="45">

                           {ourClientsSays.map((val, ind) =>
                              <Testimonialcard
                                 key={ind}
                                 img={val.img}
                                 name={val.name}
                                 post={val.role}
                                 review={val.review}
                              />
                           )}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );

}
export default Testimonial;