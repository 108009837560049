import React from 'react';
import { CONTACT_DETAILS } from '../../../config/constants';
import Contactcard from './content/Contactcard';

//Contact us screen
const contact = ({ t }) => {
  
  return (
    <>
      <section className="conatct">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6 "></div>

            <div className="col-xl-4 col-lg-6 col-md-6  mt-sm-0 mt-md-0 mt-lg-0 mt-4">
              <Contactcard
                img="images/contact-img1.jpg"
                name=""
                des={t("CompanyAddressValue")}
                ph1={CONTACT_DETAILS.PHONE}
                ph2=""
                email1={CONTACT_DETAILS.EMAIL}
                email2=""
              />
            </div>

            <div className="col-xl-4 col-12   mt-lg-0 mt-4"></div>
          </div>
        </div>

      </section>

    </>
  );

}
export default contact;