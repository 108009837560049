import React from 'react';
import { useTranslation } from 'react-i18next';
//import { OTHER_DETAILS } from '../../../config/constants';
// import Aboutcard from './Aboutcard';

const Aboutus = () => {

   const { t } = useTranslation();

   return (
      <>
         <section className="about-us" id="AboutUs">
            <img className="img-fluid pt-bg-img" src="images/about/demo-4.png" alt="background_image" />
            <div className="container">
               <div className="row ">
                  <div className="col-xl-6">
                     {/* <div className="pt-big-title mt-4">
                        <h2 className="pt-big-title1 wow animate__fadeInRight" data-wow-duration="1s"
                           data-wow-delay="1s">{t("whyUs")}</h2>
                     </div> */}
                     <div className="pt-section-title-box">
                        <h2 className="pt-section-sub-title custom-divider-vertically">{t("AbuMokhTashtioit")}</h2>
                        {/* <h2 className="pt-section-title">{t("whyUs")}</h2> */}
                     </div>
                     {/* <ul className="icon-list-items">
                        {Array.from({length: 4}, (_, i) => i).map((_, ind) => 
                           <Aboutcard key={ind} des={t("whyUsServices.s" + (ind + 1))}/>
                        )}
                     </ul> */}
                     <label>{t("aboutUsDesc.a1")}</label>
                     <br />
                     <label>{t("aboutUsDesc.a2")} <b>{t("AbuMokhTashtioit")}</b> {t("aboutUsDesc.a3")}</label>
                     <br />
                     <label>{t("aboutUsDesc.a4")}</label>
                     <br />
                     <label>{t("aboutUsDesc.a5")}</label>
                  </div>
                  <div className="col-xl-6">

                     <div className="d-about-container">
                        <div id="44c56" className="content__image-wrap content__image-wrap--half" data-color="#FF631B"
                           data-direction="lr">
                           <img className="img-fluid about-img" src="images/new/20.png" alt="demo-img" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );

}
export default Aboutus;