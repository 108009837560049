import React, { useEffect } from 'react';
import Loader from '../../Loader';
import Nav from '../../Navigationbar/Navigationbar-one-main';
import Banner from '../../Banner'
import Services from '../../Services/Service-one';
import ServicesDesc from '../Aboutus/content/Services';
// import Project from '../../Recent/Recent-one';
import Sidebar from "../../Sidebar";
import Footer from '../../Footer';
import ScrollTop from '../../ScrollTop';
import { useTranslation } from 'react-i18next';
import WhatsappButton from '../../WhatsappButton';
import { updateMenuActive } from '../../../config/constants';
const Ourservices = () => {
    
    const {t} = useTranslation();
    
    useEffect(() => {
        setTimeout(() => {
          updateMenuActive("services")
        }, 400);
      }, []);

    return (
        <>
            <Loader />
            <Nav/>
            <Sidebar />
            <Banner name ={t("OurServices")}/>
            <Services />
            <ServicesDesc />
            {/* <Project /> */}
            <Footer />
            <ScrollTop />
            <WhatsappButton />
        </>
    );

}
export default Ourservices;