import React from 'react';
import Loader from '../../Loader'; 
import Nav from '../../Navigationbar/Navigationbar-one-main';
import Banner from '../../Banner';
import Portfolio from './Portfolio';
import Action from '../../Action';
import Footer from '../../Footer';
import ScrollTop from '../../ScrollTop';
import Sidebar from '../../Sidebar';
import WhatsappButton from '../../WhatsappButton';
const ThreeColumns = () => {
    return (
        <>
         <Loader />
         <Nav/>
         <Sidebar />
         <Banner name ="Portfolio Single" />
         <Portfolio />
         <Action/>
         <Footer />
         <ScrollTop />
         <WhatsappButton />
        </>

    );
}
export default ThreeColumns;