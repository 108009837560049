import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CONTACT_DETAILS } from "../config/constants";

const WhatsappButton = () => {

	const {t} = useTranslation();
	const [isActive, setIsActive] = useState(false);
	
	const startChat = () => {
		let url = `https://wa.me/${CONTACT_DETAILS.WHATSAPP}`;
		window.open(url, "_blank");
	}

	return (
		<>
			<div className="whatsapp-container">

				<div className="whatsapp-demo-container">
					<div className={"whatsapp-demo" + (isActive ? " active" : "")}>
						<div className="wapp-close-container">
							<i className="fa fa-times pointer" onClick={() => setIsActive(!isActive)}></i>
						</div>
						<div className="wapp-user-info-container">
							<div className="wapp-user-active">
								<div className="wapp-user-image">
									<div className="wapp-user-image-img"></div>
								</div>
							</div>
							<div className="wapp-user-name">
								<label>{t("WAPPUserName")}</label>
							</div>
						</div>
						<div className="wapp-chat-container">
							<div className="wapp-chat">
								<div className="wapp-message">
									<label className="wapp-message-sender">{t("WAPPUserName")}</label>
									<label className="wapp-message-content dir-ltr">
										{t("WAPPUserMessage")} <i className="fa fa-heart"></i>
									</label>
								</div>
							</div>
						</div>
						<div>
							<div className="wapp-btn-send pointer"
								onClick={startChat}>
								<label className="pointer">
									<i className="fab fa-whatsapp"></i> {t("StartChatNow")}
								</label>
							</div>
						</div>
					</div>
				</div>

				<a className="topbtn" href="#whatsapp" onClick={() => setIsActive(!isActive)}> <i className="fab fa-whatsapp"></i> {t("NeedHelp?")} </a>
			</div>
		</>
	);
};
export default WhatsappButton;
