import React from 'react';
import { useTranslation } from 'react-i18next';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import "./style.css"

const Banner = () => {
   // const style1 = {
   //    background: "transparent", padding: "0", margin: "0px auto", marginTop: "0", marginBottom: "0"
   // }

   // const style2 = {
   //    zIndex: "10",
   //    direction: "rtl",
   //    fontSize: 27
   // }

   // const style4 = {
   //    zIndex: "12",
   //    fontFamily: "Jost , sansserif"
   // }
   // const style5 = {
   //    zIndex: "8",
   //    backgroundColor: "#002155",
   //    fontFamily: "Jost, sansserif"
   // }
   // const style7 = {
   //    zIndex: "9",
   //    backgroundColor: "#ff5e14",
   //    fontFamily: "Jost, sansserif"
   // }

   const { t } = useTranslation();

   const bannerImages = [
      { title: t("BannerMenu.b1"), img: "/images/new/2.png", color: "#fff" },
      { title: t("BannerMenu.b2"), img: "/images/new/3.png", color: "#fff" },
      { title: t("BannerMenu.b3"), img: "/images/new/4.png", color: "#fff" }
   ]

   return (
      <>
         <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false}>
            {bannerImages.map((item, ind) =>
               <div key={ind} className="banner---wrapper">
                  <div className="overlay"></div>
                  <img src={item.img} alt="" />
                  {/* <p className="legend" style={{color: item.color}}>{item.title}</p> */}
               </div>
            )}
         </Carousel>
      </>
   )

   // return (
   //    <>
   //       <p className="rs-p-wp-fix"></p>
   //       <rs-module-wrap id="rev_slider_1_1_wrapper" data-alias="slider-1-1" data-source="gallery"
   //          style={style1}>
   //          <rs-module id="rev_slider_1_1" style={{ display: "none" }} data-version="6.2.2">
   //             <rs-slides>

   //                {bannerImages.map((item, ind) =>
   //                   <rs-slide key={ind} data-key={"rs-" + (ind + 1)} data-title="Slide" data-thumb={item.img}
   //                      data-anim="ei:d;eo:d;s:600;r:0;t:fade;sl:0;">
   //                      <img src={item.img} title="bg-21.jpg" width="1920" height="1080" alt="banner" className="rev-slidebg"
   //                         data-no-retina style={{ objectFit: "cover" }} />


   //                      <rs-layer id="slider-1-slide-1-layer-2" data-type="text" data-color="#000" data-rsp_ch="on"
   //                         data-xy="xo:45px,40px,30px,15px;y:m;yo:-17px,-13px,0,-22px;"
   //                         data-text="w:normal;s:92,72,72,50;l:92,72,72,50;fw:600;" data-frame_0="x:50,39,29,17;"
   //                         data-frame_1="st:900;sp:1000;" data-frame_999="o:0;st:w;"
   //                         style={style2}>

   //                         <rs-layer id="slider-1-slide-1-layer-2" data-type="text" data-color="#002155" data-rsp_ch="on"
   //                            data-xy="xo:45px,40px,30px,15px;y:m;yo:-17px,-13px,0,-22px;"
   //                            data-text="w:normal;s:92,72,72,50;l:92,72,72,50;fw:600;" data-frame_0="x:50,39,29,17;"
   //                            data-frame_1="st:900;sp:1000;" data-frame_999="o:0;st:w;"
   //                            style={style2}>Build A Better<br />
   //                            World
   //                         </rs-layer>
   //                         {/*/*  <!--
   //                   --> */
   //                         }<rs-layer id="slider-1-slide-1-layer-3" data-type="text" data-color="#565969" data-rsp_ch="on"
   //                            data-xy="xo:45px,40px,30px,15px;y:m;yo:108px,95px,76px,45px;"
   //                            data-text="w:normal;s:16,16,16,11;l:32,32,32,24;" data-dim="minh:0px,none,none,none;"
   //                            data-vbility="t,t,f,f" data-frame_0="x:50,39,29,17;" data-frame_1="st:1200;sp:1000;"
   //                            data-frame_999="o:0;st:w;" style={style2}>It is a long
   //                            established
   //                            fact that a reader will be distracted by the readable content of a<br />page when looking at its
   //                            layout.
   //                         </rs-layer>
   //                         <a href="/aboutus" >

   //                            <rs-layer id="slider-1-slide-1-layer-4" href="/aboutme" className="rev-btn" data-type="button" data-rsp_ch="on"
   //                               data-xy="xo:45px,40px,30px,15px;y:m;yo:204px,180px,125px,63px;"
   //                               data-text="w:normal;s:16,16,16,18;l:32,30,32,30;" data-dim="minh:0px,none,none,none;"
   //                               data-padding="t:12,9,7,4;r:32,25,19,12;b:12,9,7,4;l:32,25,19,12;" data-frame_0="x:50,39,29,17;"
   //                               data-frame_1="st:1500;sp:1000;" data-frame_999="o:0;st:w;"
   //                               data-frame_hover="bgc:#002155;bor:0px,0px,0px,0px;sp:200;e:power1.inOut;"
   //                               style={style7}>Explore More
   //                            </rs-layer>

   //                         </a>
   //                         {/*  <!--
   //                   --> */}
   //                         <rs-layer id="slider-1-slide-1-layer-5" data-type="text" data-color="#ff631b" data-rsp_ch="on"
   //                            data-xy="xo:50px,40px,30px,15px;y:m;yo:-137px,-107px,-97px,-100px;"
   //                            data-text="w:normal;s:16,16,16,18;l:30,32,32,30;fw:600;" data-padding="l:10,8,6,4;"
   //                            data-border="bos:solid;boc:#ff5e14;bow:0,0,0,2px;" data-frame_0="x:50,39,29,17;"
   //                            data-frame_1="st:600;sp:1000;" data-frame_999="o:0;st:w;"
   //                            style={style4}>Great Experience In Building. </rs-layer>
   //                         {/*  <!--
   //                   --> */}
   //                         <a href="/aboutus" >
   //                            <rs-layer id="slider-1-slide-1-layer-6" href="/aboutme" className="rev-btn" data-type="button" data-rsp_ch="on"
   //                               data-xy="xo:220px,199px,170px,154px;y:m;yo:204px,180px,125px,63px;"
   //                               data-text="w:normal;s:16,16,16,18;l:32,30,32,30;" data-dim="minh:0px,none,none,none;"
   //                               data-padding="t:12,9,7,4;r:32,25,19,12;b:12,9,7,4;l:32,25,19,12;" data-frame_0="x:50,39,29,17;"
   //                               data-frame_1="st:1800;sp:1000;" data-frame_999="o:0;st:w;"
   //                               data-frame_hover="bgc:#ff631b;bor:0px,0px,0px,0px;sp:200;e:power1.inOut;"
   //                               style={style5}>Get In Touch<br />
   //                            </rs-layer>
   //                         </a>
   //                         {/*  <!--   "z-index:8;background-color:#002155;font-family:Jost, sans-serif;"
   //                   --> */}

   //                      </rs-layer>

   //                      <rs-layer id="slider-1-slide-1-layer-3" data-type="text" data-color="#565969" data-rsp_ch="on"
   //                         data-xy="xo:45px,40px,30px,15px;y:m;yo:108px,95px,76px,45px;"
   //                         data-text="w:normal;s:16,16,16,11;l:32,32,32,24;" data-dim="minh:0px,none,none,none;"
   //                         data-vbility="t,t,f,f" data-frame_0="x:50,39,29,17;" data-frame_1="st:1200;sp:1000;"
   //                         data-frame_999="o:0;st:w;" style={style2}>

   //                      </rs-layer>
   //                   </rs-slide>
   //                )}

   //             </rs-slides>
   //             <rs-progress className="rs-bottom" style={{ visibility: "hidden !important" }}></rs-progress>
   //          </rs-module>
   //       </rs-module-wrap>
   //    </>
   // );

}
export default Banner;