import React from 'react';


const Clientcard = (prop) => {
   return (
      <>
         <div className="item">
            <div className="pt-client-grid">
               <img className="pt-client-img" src={prop.img} alt="pt-client-img" />
            </div>
         </div>
      </>
   );

}
export default Clientcard;