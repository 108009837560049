import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ENV, isValidEmail } from '../../../config/constants'
import { HttpRequest, HttpRequestTypes } from '../../../config/http'

const Map = () => {
   const style1 = {
      backgroundImage: "url('images/bg/03.png')",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      opacity: "0.2"
   }

   const { t } = useTranslation();

   // url: https://warm-cove-09007.herokuapp.com/api/email
   //post request
   //body param: {
   //  "name": "Ashraf Masarwey",
   //  "email": "ashraf.masarwey@hotmail.com",
   //  "subject": "I need some fiber",
   //  "message": "Can you provide me some price proposal?\nMy Phone Number is: 0504844644"
   // }

   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [subject, setSubject] = useState("");
   const [message, setMessage] = useState("");

   const [errorMessage, setErrorMessage] = useState("");
   const [successMessage, setSuccessMessage] = useState("");

   const sub = async (e) => {
      e.preventDefault();

      setErrorMessage("");
      setSuccessMessage("");

      try {

         if (name.trim().length === 0) {
            setErrorMessage(t("FullNameRequired"));
         } else if (email.trim().length === 0) {
            setErrorMessage(t("EmailRequired"));
         } else if (subject.trim().length === 0) {
            setErrorMessage(t("SubjectRequired"));
         } else if (message.trim().length === 0) {
            setErrorMessage(t("MessageRequired"));
         } else if (!isValidEmail(email.trim())) {
            setErrorMessage(t("InvalidEmail"));
         } else {
            let url = `${ENV.API_URL}/${ENV.EMAIL_URL}`;

            let params = {
               name,
               email,
               subject,
               message
            };

            let res = await HttpRequest(url, params, HttpRequestTypes.POST, null);

            console.log(res);

            setSuccessMessage(t("ContactSuccessMessage"));

            setTimeout(() => {
               setSuccessMessage("");

               setName("");
               setEmail("");
               setSubject("");
               setMessage("");
            }, 2500);
         }
      } catch (e) {
         console.log(e);
      }
   }


   return (
      <>
         <section className="pt-contact-form p-0">
            <div className="container-fluid p-0">
               <div className="row no-gutters">
                  <div className="col-xl-6">
                     <div className="p-4 p-md-5 pt-bg-light">
                        <div className="pt-bg-overley" id="mapone"
                           style={style1}>
                        </div>
                        <div className="pt-section-title-box text-left">
                           <h2 className="pt-section-title">{t("DoYouHaveAnyQuestion")}</h2>
                        </div>
                        <form onSubmit={sub} className="quote-from">
                           <div className="row">
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <input type="text" className="form-control" name="name" onChange={(e) => setName(e.target.value)} value={name} id="exampleInputName" placeholder={t("YourFullName")} />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <input type="email" className="form-control" name="email" onChange={(e) => setEmail(e.target.value)} value={email} id="Email" placeholder={t("YourEmail")} />
                                 </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="form-group">
                                    <input type="text" className="form-control" name="subject" onChange={(e) => setSubject(e.target.value)} value={subject} id="Subject1" placeholder={t("Subject")} />
                                 </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="form-group">
                                    <textarea className="form-control" name="message" onChange={(e) => setMessage(e.target.value)} value={message} id="exampleFormControlTextarea1" rows="6"
                                       placeholder={t("Message")}></textarea>
                                 </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="form-group">
                                    <button type="submit" className="btn btn-primary">{t("SendMessage")}</button>
                                 </div>
                              </div>

                              {
                                 errorMessage.length > 0 && (
                                    <div className="col-md-12">
                                       <div className="form-group dd-rtl-text-right">
                                          <label className="text-danger">{errorMessage}</label>
                                       </div>
                                    </div>
                                 )
                              }

                              {
                                 successMessage.length > 0 && (
                                    <div className="col-md-12">
                                       <div className="form-group dd-rtl-text-right">
                                          <label className="text-success">{successMessage}</label>
                                       </div>
                                    </div>
                                 )
                              }
                           </div>
                        </form>
                     </div>
                  </div>
                  <div className="col-xl-6">
                     <div className="map1">
                        <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3367.7675118217007!2d35.03269808495879!3d32.42538510913853!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d100475b8a55d%3A0xf0735ad8984ecddd!2z15DXkdefINeXJ9ec15PXldefLCDXkdeQ16fXlCDXkNecLdeiJ9eo15HXmdeU!5e0!3m2!1siw!2sil!4v1639823687583!5m2!1siw!2sil" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );

}
export default Map;