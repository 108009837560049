import React, { useEffect } from 'react';
import Loader from '../../Loader';
import Nav from '../../Navigationbar/Navigationbar-one-main';
import Banner from '../../Banner'
import AboutusFiber from '../../Home/Construction/AboutusFiber';
import Team from '../../Team/Team-one';
import Sidebar from "../../Sidebar";
import Footer from '../../Footer';
import Video from '../../Video/Video-one';
import ScrollTop from '../../ScrollTop';
import { useTranslation } from 'react-i18next';
import WhatsappButton from '../../WhatsappButton';
import { updateMenuActive } from '../../../config/constants';

const FiberScreen = () => {
    
    const {t} = useTranslation();
    
    useEffect(() => {
        setTimeout(() => {
          updateMenuActive("fiber")
        }, 400);
      }, []);

    return (
        <>
            <Loader />
            <Nav />
            <Sidebar />
            <Banner name={t("Fiber")} />
            <AboutusFiber />
            <Video />
            <Team />
            <Footer />
            <ScrollTop />
            <WhatsappButton />
        </>
    );

}
export default FiberScreen;