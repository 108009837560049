import React, { useEffect } from 'react';
import Loader from '../../Loader';
import Mainnav from '../../Navigationbar/Navigationbar-one-main';
import Banner from './Banner';
import Sidebar from "../../Sidebar";
import Aboutus from '../../Aboutus/Aboutus-one';
// import Recent from '../../Recent/Recent-one';
import Video from '../../Video/Video-one';
import Services from '../../Services/Service-one';
import Counter from '../../Counter/Counter-one';
import Team from '../../Team/Team-one';
import Testimonial from '../../Testimonial/Testimonial-one';
import Client from '../../Client/Client-one';
import Footer from '../../Footer';
import ScrollTop from '../../ScrollTop';
import WhatsappButton from '../../WhatsappButton';
import { updateMenuActive } from '../../../config/constants';

const Home = () => {
  
  useEffect(() => {
    setTimeout(() => {
      updateMenuActive("home")
    }, 400);
  }, []);
  
  return (
    <>
      <Loader />
      <Sidebar />
      <Mainnav />
      <Banner />
      <Aboutus />
      {/* <Recent /> */}
      <Video />
      <Services />
      <Counter />
      <Team />
      <Testimonial />
      <Client />
      <Footer />
      <ScrollTop />
      <WhatsappButton />
    </>
  );

}
export default Home;