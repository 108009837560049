import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { CONTACT_DETAILS } from '../config/constants';


const Sidebar = () => {

   const { t } = useTranslation();

   const click2 = (e) => {
      return e.preventDefault()
   }
   return (
      <>
         <div className="pt-background-overlay"></div>
         <div className="pt-sidebar">
            <div className="pt-close-btn">
               <NavLink className="pt-close" to="" onClick={click2}>
                  <i className="ion-close-round"></i>
               </NavLink>
            </div>
            <div className="pt-sidebar-block">
               <div className="pt-sidebar-header">
                  <img src="images/logo.png" className="pt-sidebar-logo"
                     alt="architeck-sidebar-logo" />
                  <p>{t("AbuMokhTashtioit")}</p>
               </div>
               <div className="pt-sidebar-category">
                  <h5>{t("Categories")}</h5>
                  <ul>
                     {Array.from({length: 9}, (_, i) => i).map((_, ind) =>
                        <li className="pointer hover-eff" key={ind}>{t("Services.s" + (ind + 1))}</li>
                     )}
                  </ul>
               </div>
               <div className="pt-sidebar-contact">
                  <h5>{t("ContactInfo")}</h5>
                  <ul className="pt-sidebar-contact">
                     <li>
                        <i className="fa fa-map-marker"></i>
                        <span>{t("CompanyAddressValue")}</span>
                     </li>
                     <li>
                        <a href={"tel:" + CONTACT_DETAILS.PHONE.replace("-", "")}><i className="fa fa-phone"></i>
                           <span>{CONTACT_DETAILS.PHONE}</span>
                        </a>
                     </li>
                     <li>
                        <a href={"mailto:" + CONTACT_DETAILS.EMAIL}><i
                           className="fa fa-envelope"></i><span>{CONTACT_DETAILS.EMAIL}</span></a>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </>
   );

}
export default Sidebar;