import React from 'react';
import { useTranslation } from 'react-i18next';


const Aboutus = () => {

   const { t } = useTranslation();

   return (
      <>
         <section className="about-us">
            <div className="container">

               <div className="row">
                  <div className="col-xl-6">
                     <div className="pt-section-title-box">
                        <h2 className="pt-section-sub-title">{t("aboutTitle")}</h2>
                     </div>
                     <label style={{width: "100%"}}>{t("aboutTitleDesc.a1")}</label>
                     <br />
                     <label>{t("aboutTitleDesc.a2")}</label>
                     <br />
                     <label>{t("aboutTitleDesc.a3")} <b>1997</b> {t("aboutTitleDesc.a4")}</label>
                     <br />
                     <label>{t("aboutTitleDesc.a5")}</label>
                     <br />
                     <label>{t("aboutTitleDesc.a6")}</label>
                  </div>
                  <div className="col-xl-6">

                     <div className="d-about-container">
                        <div id="44c56" className="content__image-wrap content__image-wrap--half" data-color="#FF631B"
                           data-direction="lr">
                           <img className="img-fluid about-img" src="images/new/17.png" alt="demo-img" />
                        </div>
                     </div>
                  </div>
               </div>


               <div className="row" style={{marginTop: 50}}>
                  <div className="col-xl-6">
                     <div className="d-about-container">
                        <div id="44c54" className="content__image-wrap content__image-wrap--half" data-color="#FF631B"
                           data-direction="lr">
                           <img className="img-fluid about-img" src="images/new/20.png" alt="demo-img" />
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-6">
                     <div className="pt-section-title-box">
                        <h2 className="pt-section-sub-title">{t("uniqueness")}</h2>
                     </div>
                     <label>{t("uniquenessDesc")}</label>
                  </div>
               </div>


            </div>
         </section>
      </>
   );

}
export default Aboutus;