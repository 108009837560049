import './App.css';
import MainNavigation from './components/MainNavigation';
import { BrowserRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { updateRTLDirections } from './config/constants';

function App() {

  useEffect(() => {
    let lang = localStorage.getItem("lang");

    if (lang !== null && lang === "he") {
      updateRTLDirections(lang);
    }
    
  }, []);

  return (
    <>
      <BrowserRouter>
        <MainNavigation />
      </BrowserRouter>
    </>
  );
}

export default App;
