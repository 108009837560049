import React from 'react';
import { useTranslation } from 'react-i18next';
//import Servcard from './Servcard';


const Service = () => {

   const { t } = useTranslation();

   const servicesDetails = [
      { img: "images/blog/blog-1.jpg", name: t("Services.s1"), description: t("ServiceDescriptions.s1") },
      { img: "images/blog/blog-2.jpg", name: t("Services.s2"), description: t("ServiceDescriptions.s2") },
      { img: "images/blog/blog-3.jpg", name: t("Services.s3"), description: t("ServiceDescriptions.s3") },
      { img: "images/blog/blog-4.jpg", name: t("Services.s4"), description: t("ServiceDescriptions.s4") },
      { img: "images/blog/blog-6.jpg", name: t("Services.s5"), description: t("ServiceDescriptions.s5") },
      { img: "images/blog/blog-7.jpg", name: t("Services.s6"), description: t("ServiceDescriptions.s6") },
      { img: "images/blog/blog-1.jpg", name: t("Services.s7"), description: t("ServiceDescriptions.s7") },
      { img: "images/blog/blog-2.jpg", name: t("Services.s8"), description: t("ServiceDescriptions.s8") },
      { img: "images/blog/blog-3.jpg", name: t("Services.s9"), description: t("ServiceDescriptions.s9") }
   ]

   return (
      <>
         <section className="services wow animate__fadeIn" data-wow-delay="0.2s" id="iservices">
            <div className="container">
               {/* <div className="row">
                  <div className="col-xl-12 text-center">
                     <div className="pt-big-title mt-4">
                        <h2 className="pt-big-title1  wow animate__fadeInUp" data-wow-duration="1s"
                           data-wow-delay="1s">{t("ServicesTitle")}</h2>
                     </div>
                     <div className="pt-section-title-box">
                        <span className="pt-section-sub-title">{t("WhatWeOffer")}</span>
                        <h2 className="pt-section-title">{t("AbuMokhTashtioit")}</h2>
                        <p className="pt-section-description">{t("YourFiberInstraPartner")}</p>
                     </div>
                  </div>
               </div> */}
               {/* <div className="row">

                  {servicesDetails.map((val, ind) =>
                     <Servcard
                        key={ind}
                        img={val.img}
                        title={val.name}
                        des={val.description}
                     />
                  )}
               </div> */}



               <div className="row " style={{flexDirection: localStorage.getItem("lang") === "he" ? "row" : "row-reverse"}}>
                  <div className="col-xl-6">
                     <ul className="services---container">
                        {
                           servicesDetails.map((val, ind) =>
                              <li key={ind}>
                                 <h4>{val.name}</h4>
                              </li>
                           )
                        }
                     </ul>
                  </div>
                  <div className="col-xl-6">

                     <div className="d-about-container">
                        <div id="44c55" className="content__image-wrap content__image-wrap--half" data-color="#FF631B"
                           data-direction="lr">
                           <img className="img-fluid about-img" src="images/new/14.png" alt="demo-img" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );

}
export default Service;