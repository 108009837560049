import React, { useState } from 'react';
import { ENV } from '../../../config/constants';
import { HttpRequest, HttpRequestTypes } from '../../../config/http';
const Conatct = () => {


   // url: https://warm-cove-09007.herokuapp.com/api/email
   //post request
   //body param: {
   //  "name": "Ashraf Masarwey",
   //  "email": "ashraf.masarwey@hotmail.com",
   //  "subject": "I need some fiber",
   //  "message": "Can you provide me some price proposal?\nMy Phone Number is: 0504844644"
   // }

   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [subject, setSubject] = useState("");
   const [message, setMessage] = useState("");

   const sub = async (e) => {
      e.preventDefault();

      try{
         let url = `${ENV.API_URL}/${ENV.EMAIL_URL}`;
         
         let params = {
            name,
            email,
            subject,
            message
         };

         let res = await HttpRequest(url, params, HttpRequestTypes.POST, null);
         
         console.log(res);

      } catch(e) {
         console.log(e);
      }
   }

   return (
      <>
         <section className=" conatct pt-contact-form pt-section-overlap pt-5">
            <div className="container">
               <div className="row no-gutters">
                  <div className="col-xl-6 z-index-1">
                     <div className="form p-4 p-md-5 pt-bg-light">
                        <div className="pt-bg-overley pt-opacity1 "
                           style={{ backgroundImage: "url('images/bg/03.png')" }}>
                        </div>
                        <div className="pt-section-title-box">
                           <span className="pt-section-sub-title">Get Quote</span>
                           <h2 className="pt-section-title">Request A Quote</h2>
                        </div>
                        <form onSubmit={sub} className="quote-from">
                           <div className="row">
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <input type="text" name="name" className="form-control" onChange={(e) => setName(e.target.value)} value={name} id="exampleInputName" placeholder="Enter name" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <input type="email" name="email" className="form-control" onChange={(e) => setEmail(e.target.value)} value={email} id="Email" placeholder="Enter email" />
                                 </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="form-group">
                                    <input type="text" name="subject" className="form-control" onChange={(e) => setSubject(e.target.value)} value={subject} id="Subject" placeholder="Enter subject" />
                                 </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="form-group">
                                    <textarea className="form-control" name="message" onChange={(e) => setMessage(e.target.value)} value={message} id="exampleFormControlTextarea1" rows="6"
                                       placeholder="Enter your message"></textarea>
                                 </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="form-group">
                                    <input type="submit" value="Send Message" className="wpcf7-form-control wpcf7-submit" />
                                 </div>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
                  <div className="col-xl-6 d-lg-inline-block">
                     <img src="images/about/demo-5.jpg" className="img-fluid" alt="architeck" />
                  </div>
               </div>
            </div>
         </section>
      </>
   );

}
export default Conatct;