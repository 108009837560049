import React from 'react';


const Teamcard = (prop) => {
   return (
      <>
         <div className="item">
            <div className="pt-team-box text-center">
               <div className="pt-team-img">
                  <img className="img-fluid" src={prop.img} alt="team" />
               </div>
               <div className="pt-team-info">
                  <span className="pt-team-designation">{prop.role}</span>
                  <h5><a href="/#" className="pt-member-name">{prop.name}</a></h5>
               </div>
            </div>
         </div>
      </>
   );

}
export default Teamcard;