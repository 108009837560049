import React from 'react';


const Loader = () => {
    return (
        <>
            {/* <div id="pt-loading">
                <div id="pt-loading-center">
                    <img src="images/logo.png" alt="loading" />
                </div>
            </div> */}
        </>
    );

}
export default Loader;