import React from 'react';


const Testimonialcard = (prop) => {
   return (
      <>
         <div className="item">
            <div className="pt-testimonial-box pt-testimonial-box-1">
               <div className="pt-testimonial-img">
                  <img src={prop.img} alt="testimonial" />
               </div>
            </div>
         </div>
      </>
   );

}
export default Testimonialcard;