import React from 'react';
import Clientcard from './Clientcard';

const clientsLogos = [
   { img: "images/client/c1.jpg" },
   { img: "images/client/c2.jpg" },
   { img: "images/client/c3.jpg" },
   { img: "images/client/c4.jpg" },
   { img: "images/client/c5.jpg" },
   { img: "images/client/c6.jpg" },
   { img: "images/client/c7.jpg" },
   { img: "images/client/c8.jpg" },
   { img: "images/client/c9.jpg" }
];

const Client = () => {

   return (
      <>
         <div className="client pt-bg-light py-4">
            <div className="container-fluid">
               <div className="row">
                  <div className="col-sm-12">
                     <div className="owl-carousel owl-loaded owl-drag" data-dots="false" data-nav="false" data-desk_num="6"
                        data-lap_num="4" data-tab_num="3" data-mob_num="1" data-mob_sm="1" data-autoplay="true"
                        data-loop="true" data-margin="30">

                        {clientsLogos.map((val, ind) =>
                           <Clientcard
                              key={ind}
                              img={val.img} />
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );

}
export default Client;