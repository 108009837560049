import React from 'react';
import { useTranslation } from 'react-i18next';


const AboutusFiber = () => {
   
   const {t} = useTranslation();
   
   return (
      <>
         <section className="about-us">
            <div className="container">
               <div className="row d-flex align-items-center">
                  <div className="col-xl-6  col-lg-12 text-center">
                     <div id="44c58" className="content__image-wrap content__image-wrap--half" data-color="#FF631B"
                        data-direction="lr">
                        <img className="img-fluid" src="images/new/17.png" alt="demo-img" />
                     </div>
                  </div>
                  <div className="col-xl-6 pt-lg-4 col-12 pt-md-4 mt-4">
                     <div className="pt-section-title-box text-left">
                        <span className="pt-section-sub-title">{t("FiberExperts")}</span>
                        <h2 className="pt-section-title">{t("AbousUsDescription")}</h2>
                        <p className="pt-section-description">
                           {t("AboutDescItems.a1")}
                           <br />
                           {t("AboutDescItems.a2")}
                           <br />
                           {t("AboutDescItems.a3")}
                           <br />
                           {t("AboutDescItems.a4")}
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );

}
export default AboutusFiber;