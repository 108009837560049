import React, { useEffect } from 'react';
import Loader from '../../Loader';
import Nav from '../../Navigationbar/Navigationbar-one-main';
import Banner from '../../Banner'
import Aboutus from '../../Home/Construction/Aboutus';
//import Services from './content/Services';
import Team from '../../Team/Team-one';
import Sidebar from "../../Sidebar";
import Footer from '../../Footer';
import Video from '../../Video/Video-one';
import ScrollTop from '../../ScrollTop';
import { useTranslation } from 'react-i18next';
import WhatsappButton from '../../WhatsappButton';
import { updateMenuActive } from '../../../config/constants';

//About US SCreen
const Construction = () => {
    
    const {t} = useTranslation();
    
    useEffect(() => {
        setTimeout(() => {
          updateMenuActive("about")
        }, 400);
      }, []);

    return (
        <>
            <Loader />
            <Nav />
            <Sidebar />
            <Banner name={t("AboutUs")} />
            <Aboutus />
            <Video />
            {/* <Services /> */}
            <Team />
            <Footer />
            <ScrollTop />
            <WhatsappButton />
        </>
    );

}
export default Construction;