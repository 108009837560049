import React, { useEffect } from 'react';
import Loader from '../../Loader';
import Nav from '../../Navigationbar/Navigationbar-one-main';
import Banner from '../../Banner';
import Contact from './Contact';
import Map from '../../Map/Map-one';
import Testimonial from '../../Testimonial/Testimonial-one';
import Sidebar from "../../Sidebar";
import Footer from '../../Footer';
import { useTranslation } from 'react-i18next';
import ScrollTop from '../../ScrollTop';
import WhatsappButton from '../../WhatsappButton';
import { updateMenuActive } from '../../../config/constants';
const Construction = () => {

    const { t } = useTranslation();

    useEffect(() => {
        setTimeout(() => {
          updateMenuActive("contact")
        }, 400);
      }, []);

    return (
        <>
            <Loader />
            <Nav />
            <Sidebar />
            <Banner name={t("ContactUs")} />
            <Map />
            <Testimonial />
            <Contact t={t} />
            <Footer />
            <ScrollTop />
            <WhatsappButton />
        </>
    );

}
export default Construction;