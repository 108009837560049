import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Teamcard from './Teamcard';

const Team = () => {

   const { t } = useTranslation();
   const [teamMembers, setTeamMembers] = useState([]);
   
   useEffect(() => {
      const images = [
         "images/team/01.jpg",
         "images/team/02.jpg",
         "images/team/03.jpg",
         "images/team/04.jpg",
         "images/team/05.jpg"
      ];

      let team = [];

      for (let i = 1; i <= 5; i++) {
         team.push({
            name: t('employeesNames.em' + i),
            role: t('employeesRoles.em' + i),
            img: images[i - 1]
         })
      }

      setTeamMembers(team);

   }, [t]);

   return (
      <>
         <section className="team md-pt-0" id="team">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12 text-center">
                     <div className="pt-big-title mt-4">
                        <h2 className="pt-big-title1  wow animate__fadeInUp" data-wow-duration="1s"
                           data-wow-delay="1s">{t("OurExpertTeam")}</h2>
                     </div>
                     <div className="pt-section-title-box">
                        <h2 className="pt-section-title">{t("OurExpertTeam")}</h2>
                     </div>
                  </div>
               </div>
            </div>
            <div className="container-pt">
               <div className="row mt-4 mt-lg-5">
                  <div className="col-sm-12">
                     <div className="owl-carousel" data-dots="false" data-nav="false" data-desk_num="4" data-lap_num="2"
                        data-tab_num="2" data-mob_num="1" data-mob_sm="1" data-autoplay="true" data-loop="true"
                        data-margin="30">

                        {teamMembers.map((val, ind) =>
                           <Teamcard
                              key={ind}
                              img={val.img}
                              role={val.role}
                              name={val.name}
                           />
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );

}
export default Team;