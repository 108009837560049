export const SCREEN_URLS = {
    HOME: "/",
    CONTACT: "/contact"
}

export const CONTACT_DETAILS = {
    PHONE: "04-6281555",
    EMAIL: "office@abumokh.com",
    FACEBOOK_URL: "#facebook",
    INSTAGRAM_URL: "#instagram",
    WHATSAPP: "972542270729",
    WAZE_URL: "#"
}

export const OTHER_DETAILS = {
    YEARS_EXPERIENCE: 25,
    PROJECTS_COMPLETED: 5000,
    HAPPY_CUSTOMERS: 3420,
    WORK_EMPLOYED: 5000,
    YOUTUBE_VIDEO_URL: "https://www.youtube.com/watch?v=XHOmBV4js_E"
}

export const ENV = {
    API_URL: "https://arcane-savannah-22117.herokuapp.com",
    EMAIL_URL: "api/email"
}

export const IsPhoneValid = (phone) => {
    let fixedPhone = "";

    if (!phone) { return false; }

    for (let i = 0; i < phone.length; i++) {
        if (phone.charAt(i) >= '0' && phone.charAt(i) <= '9') {
            fixedPhone += phone.charAt(i)
        }
    }

    return fixedPhone.length === 10 && fixedPhone.substr(0, 2) === "05";
}

export const isValidEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const changeLanguage = (lan) => {
    updateRTLDirections(lan);
    localStorage.setItem("lang", lan)
    window.location.reload();
}

export const updateRTLDirections = (lan) => {
    
    let bodyList = document.getElementsByTagName("body");
    let body = bodyList.length === 0 ? null : bodyList[0];

    if (body === null) return;

    if (lan === "he") {
        body.classList.add("dd-rtl");
    } else {
        body.classList.remove("dd-rtl");
    }
}

export const updateMenuActive = (current) => {
    
    removeAllActiveClasses();

    let curr = document.getElementById(current);

    if (curr && !curr.classList.contains("active-list")) {
        curr.classList.add("active-list");
    }
}

const removeAllActiveClasses = () => {
    let arr = ["home", "about", "services", "contact", "fiber"];

    for(let i = 0; i < arr.length; i++){
        let curr = document.getElementById(arr[i]);

        if (curr && curr.classList.contains("active-list")) {
            curr.classList.remove("active-list");
        }
    }
}