import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './translations/en.json';
import he from './translations/he.json';

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
        resources: {
            en: {
                translation: en
            },
            he: {
                translation: he
            }
        },
        lng: localStorage.getItem("lang") === null ? "en" : localStorage.getItem("lang"),
        fallbackLng: localStorage.getItem("lang") === null ? "en" : localStorage.getItem("lang"),
        detection: {
            order: ['localstorage'],
            caches: ['localstorage']
        },
        interpolation: {
            escapeValue: false,
        },
        react: { 
            useSuspense: false
        }
    });

export default i18n;