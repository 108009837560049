import React from 'react';
import { useTranslation } from 'react-i18next';


const Services = () => {

   const { t } = useTranslation();

   const servicesDetails = [
      { name: t("Services.s1"), img: "images/new/14.png", description: t("ServiceDescriptions.s1") },
      { name: t("Services.s2"), img: "images/new/17.png", description: t("ServiceDescriptions.s2") },
      { name: t("Services.s3"), img: "images/new/19.png", description: t("ServiceDescriptions.s3") },
      { name: t("Services.s4"), img: "images/new/15.png", description: t("ServiceDescriptions.s4") },
      { name: t("Services.s5"), img: "images/new/16.png", description: t("ServiceDescriptions.s5") },
      { name: t("Services.s6"), img: "images/new/21.png", description: t("ServiceDescriptions.s6") },
      { name: t("Services.s7"), img: "images/new/2.png", description: t("ServiceDescriptions.s7") },
      { name: t("Services.s8"), img: "images/new/22.png", description: t("ServiceDescriptions.s8") },
      { name: t("Services.s9"), img: "images/new/18.png", description: t("ServiceDescriptions.s9") }
   ];

   return (
      <>
         <section className="service wow animate__fadeIn" data-wow-delay="0.2s">
            <div className="container">
               <div className="row">

                  <div className="col-sm-12 mb-5">
                     <div className="pt-section-title-box">
                        <div className="pt-big-title mt-4">
                           <h2 className="pt-big-title1 wow animate__fadeInRight" data-wow-duration="1s"
                              data-wow-delay="1s">{t("AbuMokhTashtioit")}</h2>
                        </div>
                        <h2 className="pt-section-sub-title">{t("AbuMokhTashtioit")}</h2>
                     </div>
                  </div>

                  {servicesDetails.map((val, ind) =>
                     <div className="ml-auto col-lg-4 col-md-6 mb-5" key={ind}>
                        <div className="pt-service-box pt-service-box-1">
                           <div className="pt-service-banner">
                              <img className="img-fluid" src={val.img} alt="servicebox" />
                              <div className="pt-service-media">
                                 <i className=" flaticon-008-factory"></i>
                              </div>
                           </div>
                           <div className="pt-service-box-info">
                              <h4 className="pt-service-title">{val.name}</h4>
                              <p className="pt-service-description">
                                 {val.description}
                              </p>
                           </div>
                        </div>
                     </div>
                  )}
               </div>
            </div>
         </section>
      </>
   );

}
export default Services;